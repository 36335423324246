<template>
    <div class="device-detail">
        <div class="device-detail__content">
            <div class="device-detail__header">
                <div class="dd-header">
                    <div class="dd-header__line">
                        <span class="dd-header__status-icon" :style="{backgroundColor: statusColor}"></span
                        >{{ deviceDetail.originalEquipmentName }}
                        {{ deviceDetail.equipmentNumber }}
                    </div>
                    <div class="dd-header__line">
                        设备经度:{{ deviceDetail.longitude }} 设备纬度:{{ deviceDetail.latitude }} 质保日期:{{
                            deviceDetail.qualityAssuranceDate
                        }}
                    </div>
                </div>
            </div>
            <div class="device-detail__board">
                <div class="dd-board">
                    <div class="dd-board__item" v-for="(item, index) in records" :key="index">
                        <div class="dd-board__item-value">{{ item.value }}</div>
                        <div class="dd-board__item-name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <div class="device-detail__graph">
                <div class="dd-graph">
                    <div class="dd-graph__item" v-for="(item, index) in treeData" :key="index">
                        <div class="dd-graph__title">{{ item.name }}<br />{{ item.code }}</div>
                        <div class="dd-graph__link-block-horizontal">
                            <div class="dd-graph__link-left"></div>
                            <div class="dd-graph__link-right"></div>
                        </div>
                        <div class="dd-graph__link-block-vertical">
                            <div class="dd-graph__link-block-vertical-line"></div>
                        </div>
                        <div class="dd-graph__detail">{{ item.detail }}</div>
                    </div>
                </div>
            </div>
            <!-- <div class="device-detail__pic">
                <div class="device-detail__image">
                    <el-image @click="showPdf(item)" :src="getPreviewUrl(item)" fit="contain"></el-image>
                </div>
                <div class="device-detail__describe">
                    <span>图纸名称：{{ item.drawingName }}</span>
                    <span>图纸编号：{{ item.drawingCode }}</span>
                    <span class="device-detail__describe-btns"
                        ><el-button @click="showPdf(item)" size="mini" type="primary">预览</el-button
                        ><el-button @click="downloadPdf(item)" size="mini" type="primary">下载</el-button></span
                    >
                </div>
            </div> -->
        </div>
        <div class="device-detail__footer">
            <span slot="footer" class="dialog-footer">
                <el-button @click="$emit('close')" type="primary">关闭</el-button>
            </span>
        </div>
    </div>
</template>

<script>
import {previewPdf, fileDownload} from '@/utils';
export default {
    props: {
        pointData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            // item: {
            //     id: 23,
            //     drawingApplyId: 13,
            //     drawingType: 10,
            //     drawingName: '开关站单线图1',
            //     drawingCode: 'kgzdxt1',
            //     ossId: '15f83a22ed6e1369187347e79cfc7a45',
            //     createBy: 'yhk',
            //     createTime: '2020-10-15 16:27:10',
            // },
            deviceDetail: {},
            statusList: [],
            boxType: [],
            boxTypeTrans: {},
            records: [],
            treeData: [],
        };
    },
    computed: {
        statusColor() {
            let type = this.pointData.equipmentState;
            let color;
            let statusTypes = this.statusList.reduce((a, b) => {
                a[b.code] = b.code;
                return a;
            }, {});
            switch (type) {
                case statusTypes.NORMAL: //正常
                    color = '#2a82e4';
                    break;
                case statusTypes.FAILURE: //故障
                    color = '#d43030';
                    break;
                case statusTypes.RISKY: //安全隐患
                    color = '#ff8d1a';
                    break;
                case statusTypes.DAMAGED: //损坏
                    color = '#000';
                    break;
                case statusTypes.IN_MAINTENANCE: //维修中
                    color = '#d43030';
                    break;
                case statusTypes.IDLE: //闲置
                    color = '#888';
                    break;
                case statusTypes.ABANDONMENT: //废弃
                    color = '#888';
                    break;
                default:
                    color = '#888';
            }
            return color;
        },
    },
    methods: {
        getPreviewUrl(pic) {
            let url = `${this.$client.serverUrl}files/${pic.ossId}/thumbnail?token=${this.$store.state.token}`;
            return url;
        },
        getDownloadUrl(pic) {
            let url = this.axios.defaults.baseURL + '/files/' + pic.ossId + '?token=' + this.$store.state.token;
            return url;
        },
        downloadPdf(pic) {
            fileDownload(this.getDownloadUrl(pic));
        },
        showPdf(pic) {
            previewPdf(this.getDownloadUrl(pic), pic.drawingName + '  ' + pic.drawingCode);
        },
        getDetail() {
            let id = this.pointData.id;
            this.$client.getPowerGridsRingMainUnitDetail(id).then((res) => {
                let data = res.data;
                this.deviceDetail = data;
                let group = this._.groupBy(data.ringMainUnitBoxes, (it) => it.type);
                let records = [
                    {
                        name: '额定容量',
                        value: data.ratedCapacity || 0,
                    },
                    {
                        name: '当前容量',
                        value: data.usedCapacity || 0,
                    },
                    {
                        name: '进线实时电流',
                        value: data.realtimeInputCurrent || 0,
                    },
                    {
                        name: '进线实时电压',
                        value: data.realtimeInputVoltage || 0,
                    },
                ];

                for (let i in group) {
                    records.push({
                        name: this.boxTypeTrans[i],
                        value: group[i].length,
                    });
                }
                this.records = records;
                let safeNull = (v) => (v === null ? '' : v);
                let safeNumber = (v) => (v ? Number(v) : 0);
                this.treeData = data.ringMainUnitBoxes.map((it) => {
                    let detail = it.realtimeData.reduce((a, b) => {
                        return a + `${b.tagName} : ${b.value}\n`;
                    }, '');

                    detail = `名称 : ${safeNull(it.name)}
额定电压(kV) : ${safeNull(it.voltage)}
额定电流(A) : ${safeNull(it.current)}
额定功率(kVA) : ${safeNumber(it.current) * safeNumber(it.voltage)}
${detail}接入点 : ${safeNull(it.joinEquipmentName || it.joinBoxName)}
接入线路 : ${safeNull(it.wireName)}
`;
                    return {
                        name: `${this.boxTypeTrans[it.type]}`,
                        code: it.number,
                        detail,
                    };
                });
            });
        },
    },
    created() {
        const dictMap = [
            {listName: 'statusList', parentCode: 'equipment_state'},
            {listName: 'boxType', parentCode: 'ring_main_unit_box_type'},
        ];
        this.$client.listDictionaryChildrenArr(dictMap.map((it) => it.parentCode)).then((res) => {
            let {data: resData} = res;
            for (let key in resData) {
                let data = resData[key];
                let dict = dictMap.filter((it) => it.parentCode === key)[0];
                this[dict.listName] = data;
            }
            this.boxTypeTrans = this.boxType.reduce((a, b) => {
                a[b.name] = b.value;
                return a;
            }, {});
            this.getDetail();
        });
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';
.dd-board {
    display: flex;
    &__item {
        background-color: @bg-border;
        color: @bg-font;
        /* flex-basis: 90px; */
        margin: 10px 0;
        margin-left: 5px;
        flex: 1 auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__item-name {
        font-size: 12px;
    }
    &__item-value {
        font-size: 15px;
        font-weight: bolder;
    }
}
.dd-graph {
    display: flex;
    flex: 1 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    &__detail {
        white-space: break-spaces;
        padding: 0px 5px;
    }
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        /* flex-basis: 150px; */
        flex: 1;
    }
    &__link-block-horizontal {
        width: 100%;
        height: 20px;
        flex-basis: 20px;
        display: flex;
        flex: 0;
        /* align-items: revert; */
    }
    &__item:first-of-type &__link-left,
    &__item:last-of-type &__link-right {
        visibility: hidden;
    }
    &__link-block-vertical {
        height: 80px;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
    &__link-block-vertical-line {
        height: 100%;
        border-right: 1px solid @bg-btn-light;
    }
    &__link-left {
        border-bottom: 1px solid @bg-btn-light;
        width: 50%;
    }
    &__link-right {
        border-bottom: 1px solid @bg-btn-light;
        width: 50%;
    }
    &__title {
        text-align: center;
    }
}
.dd-header {
    &__line {
        line-height: 25px;
        height: 25px;
    }
    &__records-link {
    }
    &__status-icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        border-radius: 10px;
        vertical-align: middle;
        margin-right: 5px;
    }
    &__status-text {
        background-color: red;
        color: white;
        padding: 0 5px;
        border-radius: 5px;
    }
}
.device-detail {
    &__board {
    }
    &__graph {
    }
    &__header {
    }
    &__content {
        max-height: 700px;
        min-height: 400px;
        overflow: hidden scroll;
    }
    &__footer {
        text-align: center;
        padding-top: 10px;
    }
    &__pic {
        display: flex;
        margin: 20px 0;
        justify-content: center;
    }
    &__image {
        width: auto;
        height: 250px;
        text-align: center;
        /deep/ .el-image {
            cursor: pointer;
            height: 100%;
            width: auto;
        }
    }
    &__describe-btns {
        margin-top: 5px;
    }
    &__describe {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
</style>