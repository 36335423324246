<template>
    <div class="chart-map">
        <div
            class="chart-map__drop-zone"
            @drop.prevent="drop"
            @dragover.prevent="dragover"
            ref="mapWrap"
            v-resize="handleResize"
        >
            <div v-if="searchAddress" class="chart-map__search">
                <el-input
                    placeholder="请输入关键词"
                    v-model="searchKey"
                    class="input-with-select"
                    @keyup.enter.native="searchAddressInfo(searchKey)"
                >
                    <el-button slot="append" icon="el-icon-search" @click="searchAddressInfo(searchKey)"></el-button>
                </el-input>
            </div>
            <div v-if="isLineEdit" class="chart-map__panel">
                <el-button icon="el-icon-close" @click="lineMenuHandle('cancel')">取消</el-button>
                <el-button icon="el-icon-refresh-left" @click="lineMenuHandle('back')">回退</el-button>
                <el-button icon="el-icon-check" @click="lineMenuHandle('save')">提交</el-button>
            </div>
            <div id="chartwrap" style="height: 100%; width: 100%"></div>
            <el-popover
                placement="top"
                :title="popData.popTitle"
                width="360"
                trigger="click"
                ref="popover"
                popper-class="map-popover"
            >
                <div v-html="popData.popContent"></div>
                <router-link
                    v-if="toMainSubstationCurrentData"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=cbbc649f-5a20-4e6c-b6bb-9869c45d8887"
                >
                    <!--to="/currentData/bdz"-->
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--110kV汽车园变电站10kV汽车园I回线1号户外开关站-->
                <router-link
                    v-if="toHwgQcy1"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=e4f22c90-8748-4dbe-8243-44e54c0d2202"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--110kV汽车园变电站10kV汽车园Ⅱ回线1号户外开关站-->
                <router-link
                    v-if="toHwgQcy2"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=5830d8e3-7a5b-49bb-a196-4523009921a4"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--110kV汽车园变电站10kV园小线1号户外开关站-->
                <router-link
                    v-if="toHwgYx1"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=3e1e480e-b343-4351-a82b-ba5e9a9571d0"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--110kV汽车园变电站10kV园小线2号户外开关站-->
                <router-link
                    v-if="toHwgYx2"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=d6957dfc-0e86-4676-9b80-857ce36a80e6"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--110kV汽车园变电站10kV园小线3号户外开关站-->
                <router-link
                    v-if="toHwgYx3"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=e0dfd6b7-b2ba-47a2-96a7-c9718f19a6c8"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--110kV汽车园变电站10kV园小线4号户外开关站-->
                <router-link
                    v-if="toHwgYx4"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=f14ebcc5-0682-440e-add5-b3edc796c799"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--110kV汽车园变电站10kV园卡线1号户外开关站-->
                <router-link
                    v-if="toHwgYk1"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=e3a6db3e-9477-4014-815a-c19612b55d5c"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--110kV汽车园变电站10kV园卡线2号户外开关站-->
                <router-link
                    v-if="toHwgYk2"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=ff201230-5e28-4284-87cb-b2239345e600"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--110kV汽车园变电站10kV园卡线3号户外开关站-->
                <router-link
                    v-if="toHwgYk3"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=0cb31e07-b435-44cc-99a6-4069a4745a75"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--110kV汽车园变电站10kV园江线1号户外开关站-->
                <router-link
                    v-if="toHwgYj1"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=93b27b02-d4ef-48a5-aeb3-4401779d5d50"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--110kV汽车园变电站10kV园江线2号户外开关站-->
                <router-link
                    v-if="toHwgYj2"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=c82cd3da-a768-446f-be06-5c405ef2dff6"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--园小线2+1-->
                <router-link
                    v-if="toHwgYx2Add1"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=b458e1f4-1a0f-4240-ae55-25286cd2096c"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--园西线1回1-->
                <router-link
                    v-if="toHwgYx1Hui1"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=5780b8c0-66e8-4164-9aee-3cfec7c64d0d"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--园西线1回2-->
                <router-link
                    v-if="toHwgYx1Hui2"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=8a662bd8-88e0-48dd-b6a6-2645f9379e71"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--园西线2回1-->
                 <router-link
                    v-if="toHwgYx2Hui1"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=8816c6f5-fed1-40c5-b96a-50010560b9c9"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!-- 110kV汽车园变电站10kV恒昇线1号箱式变电站主接线图 -->
                <router-link
                    v-if="toHwgHs1xiang"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=1412dbad-4d9e-42aa-95b5-24bb0f8d0cd3"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!-- 110kV汽车园变电站10kV恒昇线2号箱式变电站主接线图 -->
                <router-link
                    v-if="toHwgHs2xiang"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=a306005c-0804-4191-a35e-381c50a36cfd"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!-- 110kV汽车园变电站10kV恒昇线3号箱式变电站主接线图 -->
                <router-link
                    v-if="toHwgHs3xiang"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=c1cd139e-f0e8-41ed-ae4c-c57c2adf7e12"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!-- 110kV汽车园变电站10kV恒昇线4号箱式变电站主接线图 -->
                <router-link
                    v-if="toHwgHs4xiang"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=c383b110-c396-40b8-985f-6d0e9732c566"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!-- 110kV汽车园变电站10kV恒昇线5号箱式变电站主接线图 -->
                <router-link
                    v-if="toHwgHs5xiang"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=ecc32136-806f-4cdc-b1ee-51c35e45697e"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!-- 110kV汽车园变电站10kV恒昇线6号箱式变电站主接线图 -->
                <router-link
                    v-if="toHwgHs6xiang"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=13a06e54-8997-4be9-b7a5-c1fca9a84689"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!-- 110kV汽车园变电站10kV新滇土投线1号线1号箱式变电站主接线图 -->
                <router-link
                    v-if="toHwgXdtt1h1xiang"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=435a21cb-e725-4169-8737-5b90d0509ce2"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!-- 110kV汽车园变电站10kV新滇土投线1号线2号箱式变电站主接线图 -->
                <router-link
                    v-if="toHwgXdtt1h2xiang"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=5a201722-993e-4c3d-a5ad-93d69bbae291"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!-- 110kV汽车园变电站10kV新滇土投线2号线1号箱式变电站主接线图 -->
                <router-link
                    v-if="toHwgXdtt2h1xiang"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=4a20b82f-f060-4cf0-b2f6-55e1fb2471c4"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!-- 110kV汽车园变电站10kV新滇土投线2号线2号箱式变电站主接线图 -->
                <router-link
                    v-if="toHwgXdtt2h2xiang"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=f5a58f9c-5a68-4372-876c-42a238991bd6"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!-- 110kV汽车园变电站10kV新滇土投线2号线3号箱式变电站主接线图 -->
                <router-link
                    v-if="toHwgXdtt2h3xiang"
                    class="chart-map__more-link"
                    to="/external/HtmlPanel?url=https%3A%2F%2Fbs.basic.test.ynlxkj.cn%2Fbig-screen?id=0ed8547f-29d4-4976-b74b-c31360696aef"
                >
                    <p style="color: white">>>更多信息>></p>
                </router-link>
                <!--<p v-if="showMoreInfoLink" class="chart-map__more-link" @click="showPointDetail(popData.pointData)">
                    更多信息>
                </p>-->
                <span
                    slot="reference"
                    class="chart-map__pop-trigger"
                    :style="popData.popPosition"
                    id="popTrigger"
                ></span>
            </el-popover>
        </div>
        <el-dialog
            :width="pointDialog.width"
            :top="pointDialog.top"
            :title="pointDialog.title"
            v-if="pointDialog.visible"
            :visible.sync="pointDialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <component
                :is="pointDialog.componentType"
                @close="pointDialog.visible = false"
                :pointData="popData.pointData"
            ></component>
        </el-dialog>
        <v-contextmenu ref="lineMenu">
            <v-contextmenu-item v-for="item in lineMenus" :key="item.code" @click="lineMenuHandle(item.code)">{{
                item.name
            }}</v-contextmenu-item>
        </v-contextmenu>
    </div>
</template>

<script>
import {throttle, debounce, uniqueId} from 'lodash';
import echarts from 'echarts/lib/echarts';
import {
    toPointsData,
    toLineData,
    isMapLayerClick,
    getCenterPointAndZoom,
    equipmentTypes,
    equipmentTypeTrans,
} from './utils';
import RingMainUnit from './DeviceDetailPanel/RingMainUnit';
import Substation from './DeviceDetailPanel/Substation';
import mapStyleV2 from './custom_map_config.json';

function handlePointOver(index, vueComponent) {
    vueComponent.showPointTooltip(index);
}
function handlePointOut(index, vueComponent) {}
// 模拟鼠标up事件
function simulateClick() {
    //点击位置为屏幕中间
    let sx = window.innerWidth / 2,
        sy = window.innerHeight / 2,
        cx = sx,
        cy = sy;
    let eventDown = document.createEvent('MouseEvents');
    eventDown.initMouseEvent('mousedown', true, true, window, 0, sx, sy, cx, cy, false, false, false, false, 0, null);
    let eventUp = document.createEvent('MouseEvents');
    eventUp.initMouseEvent('mouseup', true, true, window, 0, sx, sy, cx, cy, false, false, false, false, 0, null);
    //document.querySelector('.main-container').dispatchEvent(eventDown);
    document.querySelector('body').dispatchEvent(eventUp);
}
let handleDragEnd = debounce(function (index, vueComponent) {
    vueComponent.updateDragger();
    vueComponent.showPointTooltip(index);

    let myChart = vueComponent._chart;
    myChart.setOption({
        bmap: {
            roam: true,
        },
    });
    simulateClick();
}, 600);

let handleDragStart = debounce(
    function (index, vueComponent) {
        let myChart = vueComponent._chart;
        myChart.setOption({
            bmap: {
                roam: false,
            },
        });
    },
    600,
    {
        leading: true,
        trailing: false,
    }
);
function handlePointDragging(index, vueComponent) {
    handleDragStart(index, vueComponent);
    let position = this.position;
    let myChart = vueComponent._chart;
    let bmapModel = myChart.getModel().getComponent('bmap');
    let bmapCoord = bmapModel.coordinateSystem;
    let newCoords = bmapCoord.pointToData(position);
    vueComponent.points[index].coords = newCoords;
    vueComponent.updatePoint();

    handleDragEnd(index, vueComponent);
    vueComponent.updateLine();
    vueComponent.hidePopover();
    vueComponent.$emit('pointDrag', index, newCoords);
}
function handlePointMove(index, vueComponent) {
    vueComponent.showPointTooltip(index);
}

// 英文占1个字符，中文汉字占2个字符
function gblen(str) {
    let len = 0;
    for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127 || str.charCodeAt(i) === 94) {
            len += 2;
        } else {
            len++;
        }
    }
    return len;
}
//默认图标处理函数
function symbolSize(value, params) {
    let size = 12;
    let {
        data: {
            info: {equipmentMainCategory, equipmentState, propertyType},
        },
    } = params;
    switch (equipmentMainCategory) {
        case equipmentTypes.towerPole:
            size = 2;
            break;
        case equipmentTypes.mainSubstation:
            size = 40;
            break;
    }

    return size;
}
//放大后处理函数
function symbolSize1(value, params) {
    let size = 36;
    let {
        data: {
            info: {equipmentMainCategory, equipmentState, propertyType},
        },
    } = params;
    switch (equipmentMainCategory) {
        case equipmentTypes.towerPole:
            size = 30;
            break;
        case equipmentTypes.mainSubstation:
            size = 46;
            break;
    }
    return size;
}
//全局地图对象
let globalMap = null,
    globalZoom = 18;
export default {
    components: {
        RingMainUnit,
        Substation,
    },
    // computed: {
    // },
    // watch: {
    // },
    props: {
        editable: {
            type: Boolean,
            default: false,
        },
        emitAddress: {
            type: Boolean,
            default: false,
        },
        searchAddress: {
            type: Boolean,
            default: false,
        },
        lines: {
            type: Array,
            default() {
                return [];
            },
        },
        gridLines: {
            type: Array,
            default() {
                return [];
            },
        },
        points: {
            type: Array,
            default() {
                return [];
            },
        },
        mapItemIndex: {
            type: Number,
            default: undefined,
        },
    },
    watch: {
        lines() {},
        gridLines() {},
        points() {},
        mapItemIndex(newV, oldV) {
            if (newV !== oldV) {
                this.updateDragger();
            }
        },
    },
    created() {},
    mounted() {
        if (window.bmap) {
            this.initializeChart();
        } else {
            this.loadScript();
        }
    },
    data() {
        return {
            searchKey: '',
            currentLine: null,
            currentEditLine: null,
            isLineEdit: false,
            linePoint: null,
            tempLinePoint: [],
            tempLinePointCoord: [],
            popData: {
                popPosition: {},
                popTitle: undefined,
                popContent: undefined,
                pointData: {},
                componentType: 'span',
            },
            pointDialog: {
                top: '5%',
                width: '60%',
                title: '',
                visible: false,
            },
            opt: {
                // backgroundColor:'red',
                bmap: {
                    center: [102.714, 25.049],
                    zoom: 12,
                    roam: true,
                    mapOptions: {
                        enableMapClick: false,
                    },
                    mapStyleV2: {
                        styleJson: mapStyleV2,
                    },
                    // mapStyleV2
                },
                graphic: null,
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(22, 29, 48, 0.9)',
                    extraCssText: 'white-space: normal;max-width: 200px;',
                },
                animation: false,
                series: [
                    {
                        coordinateSystem: 'bmap',
                        type: 'lines',
                        data: [],
                        polyline: true,
                        lineStyle: {
                            color(item) {
                                /* let type = item.data.info.type;
                                let color;
                                switch (type) {
                                    case 'NORMAL': //正常
                                        color = '#2a82e4';
                                        break;
                                    case 'FAILURE': //故障
                                        color = '#d43030';
                                        break;
                                    case 'RISKY': //安全隐患
                                        color = '#ff8d1a';
                                        break;
                                    case 'DAMAGED': //损坏
                                        color = '#000';
                                        break;
                                    case 'IN_MAINTENANCE': //维修中
                                        color = '#d43030';
                                        break;
                                    case 'IDLE': //闲置
                                        color = '#888';
                                        break;
                                    case 'ABANDONMENT': //废弃
                                        color = '#888';
                                        break;
                                    default:
                                        color = '#c23531';
                                }*/
                                let wireId = item.data.info.id;
                                let color = '#00c865';
                                //修改变电站五条出线颜色
                                /*if (wireId === 466) {
                                    color = '#c80038';
                                } else if (wireId === 467) {
                                    color = '#c88906';
                                } else if (wireId === 468) {
                                    color = '#c80c03';
                                } else if (wireId === 469) {
                                    color = '#8708c8';
                                } else if (wireId === 470) {
                                    color = '#5203c8';
                                }*/
                                //'#2f6dc8'
                                return color;
                            },
                            opacity: 1,
                            width: 4,
                        },
                        progressiveThreshold: 500,
                        progressive: 200,
                        label: {
                            show: true,
                            position: 'middle',
                            fontSize: 10,
                            formatter: (item) => {
                                return '';
                                /*let {coords, info} = item.data;
                                let [coords1, coords2] = coords;
                                let pointA = new BMap.Point(...coords1); // 创建点坐标A
                                let pointB = new BMap.Point(...coords2); // 创建点坐标B
                                let map = this._chart.getModel().getComponent('bmap').__bmap;
                                pointA = map.pointToOverlayPixel(pointA);
                                pointB = map.pointToOverlayPixel(pointB);
                                let pixelDistance = Math.sqrt(
                                    Math.pow(Math.abs(pointA.x - pointB.x), 2) +
                                        Math.pow(Math.abs(pointA.y - pointB.y), 2)
                                );
                                // 优化：当两点之间点距离小于20px时，不显示线点名称
                                if (pixelDistance < 20) {
                                    return '';
                                }
                                return item.data.info.name;*/
                            },
                        },
                        effect: {
                            // constantSpeed: 100,
                            show: false,
                            trailLength: 0.4,
                            symbolSize: 3,
                            color: 'blue',
                        },
                        tooltip: {
                            formatter(item) {
                                let data = item.data;
                                let [startPos, endPos] = data.coords;
                                let {name, pointsIds} = data.info;
                                let str = `${name}`;
                                return str;
                            },
                        },
                    },
                    {
                        coordinateSystem: 'bmap',
                        name: '设备：',
                        type: 'scatter',
                        data: [],
                        symbol(value, params) {
                            let {
                                data: {
                                    info: {equipmentMainCategory, equipmentState, propertyType},
                                },
                            } = params;
                            if (!equipmentState) {
                                return 'circle';
                            }
                            if (equipmentState === 'NORMAL') {
                                equipmentState += '_' + propertyType;
                            }
                            return (
                                'image://' +
                                require(`@/assets/equipment/${equipmentMainCategory}_${equipmentState}.png`)
                            );
                        },
                        silent: false,
                        //symbolSize: 24,
                        symbolSize,
                        encode: {
                            value: 2,
                        },
                        /*label: {
                            //显示主变电站名称
                            formatter(item) {
                                let categoryName = item.data.info.equipmentId;
                                if (categoryName === 1) {
                                    return item.data.info.equipmentName;
                                }
                                return '';
                            },
                            // position: ['10%', '10%'],
                            position: 'top',
                            show: true,
                            fontSize: 12,
                        },*/
                        itemStyle: {
                            opacity: 1,
                            color(item) {
                                //设置颜色控制
                                let {value, info} = item.data;
                                let type = info.equipmentState;
                                let color;
                                switch (type) {
                                    case 'NORMAL': //正常
                                        color = info.propertyType === '2' ? '#2a82e4' : 'green';
                                        break;
                                    case 'FAILURE': //故障
                                        color = '#d43030';
                                        break;
                                    case 'RISKY': //安全隐患
                                        color = '#ff8d1a';
                                        break;
                                    case 'DAMAGED': //损坏
                                        color = '#000';
                                        break;
                                    case 'IN_MAINTENANCE': //维修中
                                        color = '#e7b80f';
                                        break;
                                    case 'IDLE': //闲置
                                        color = '#888';
                                        break;
                                    case 'ABANDONMENT': //废弃
                                        color = '#888';
                                        break;
                                    default:
                                        color = 'green';
                                }
                                return color;
                            },
                        },
                        emphasis: {
                            label: {
                                show: false,
                            },
                        },
                        tooltip: {
                            formatter(item) {
                                let data = item.data;
                                let [lat, lon] = data.value;
                                let {
                                    categoryName,
                                    id,
                                    equipmentMainCategory,
                                    equipmentName,
                                    totalCapacity,
                                    currentCapacity,
                                    lastCapacity,
                                } = data.info;
                                if (equipmentMainCategory === 'SWITCH') {
                                    return '开关';
                                }
                                let idShow = id ? `-${id}` : '';
                                let str = `${equipmentName ? equipmentName : categoryName}<br/>
                                    经度：${lat}<br/>
                                    纬度：${lon}`;
                                //主变负荷信息
                                if (equipmentMainCategory === 'MAIN_SUBSTATION') {
                                    str += `<br/>设计负荷：${totalCapacity}kW
                                    <br/>当前负荷：${currentCapacity}kW
                                    <br/>剩余负荷：${lastCapacity}kW`;
                                }
                                return str;
                            },
                            show: true,
                            // position: [10, 10],
                        },
                    },

                    {
                        //给地图绘制一个多边形覆盖物
                        type: 'custom',
                        coordinateSystem: 'bmap',
                        renderItem: function renderItem(params, api) {
                            let coords = [
                                [103.020515, 25.297397],
                                [103.049764, 25.296875],
                                [103.053286, 25.271212],
                                [103.0475, 25.272862],
                                [103.046207, 25.269954],
                                [103.048219, 25.270117],
                                [103.04786, 25.267699],
                                [103.045165, 25.267895],
                                [103.043692, 25.264791],
                                [103.039739, 25.26234],
                                [103.015665, 25.269823],
                                [103.013832, 25.26734],
                                [103.004921, 25.269954],
                            ];
                            let points = [];
                            for (let i = 0; i < coords.length; i++) {
                                points.push(api.coord(coords[i]));
                            }

                            return {
                                type: 'polygon',
                                shape: {
                                    points: echarts.graphic.clipPointsByRect(points, {
                                        x: params.coordSys.x,
                                        y: params.coordSys.y,
                                        width: params.coordSys.width,
                                        height: params.coordSys.height,
                                    }),
                                },
                                style: api.style({
                                    fill: 'transparent',
                                    stroke: 'red',
                                    lineWidth: 4,
                                }),
                            };
                        },
                        itemStyle: {
                            opacity: 1,
                            borderType: [10, 10],
                        },
                        z: -1,
                        animation: false,
                        silent: true,
                        clip: false,
                        data: [0],
                    },
                    {
                        //给地图绘制一个多边形覆盖物
                        type: 'custom',
                        coordinateSystem: 'bmap',
                        renderItem: function renderItem(params, api) {
                            let coords = [
                                [102.947734, 25.252222],
                                [102.960382, 25.247516],
                                [102.968431, 25.239149],
                                [102.98496, 25.231762],
                                [103.029947, 25.207965],
                                [103.065376, 25.178964],
                                [103.048129, 25.160651],
                                [103.045829, 25.151624],
                                [103.03778, 25.144952],
                                [103.028007, 25.141812],
                                [103.015646, 25.151624],
                                [102.968503, 25.164183],
                                [102.941194, 25.178049],
                                [102.923085, 25.192174],
                                [102.926822, 25.208652],
                                [102.942632, 25.228004],
                            ];
                            let points = [];
                            for (let i = 0; i < coords.length; i++) {
                                points.push(api.coord(coords[i]));
                            }
                            // let color = api.visual('color');

                            return {
                                type: 'polygon',
                                shape: {
                                    points: echarts.graphic.clipPointsByRect(points, {
                                        x: params.coordSys.x,
                                        y: params.coordSys.y,
                                        width: params.coordSys.width,
                                        height: params.coordSys.height,
                                    }),
                                },
                                style: api.style({
                                    fill: 'transparent',
                                    stroke: 'red',
                                    lineWidth: 4,
                                }),
                            };
                        },
                        itemStyle: {
                            opacity: 1,
                            borderType: [10, 10],
                        },
                        z: -1,
                        silent: true,
                        animation: false,
                        clip: false,
                        data: [0],
                    },
                ],
            },
        };
    },
    computed: {
        lineMenus() {
            if (this.isLineEdit) {
                return [
                    {
                        name: '取消',
                        code: 'cancel',
                    },
                    {
                        name: '回退',
                        code: 'back',
                    },
                    {
                        name: '提交',
                        code: 'save',
                    },
                ];
            }
            return [
                {
                    name: '编辑',
                    code: 'edit',
                },
                /*{
                    name: '重置',
                    code: 'reset'
                },*/
            ];
        },
        showMoreInfoLink() {
            let type = this.popData.pointData.equipmentMainCategory;
            if (type === equipmentTypes.ringMainUnit || type === equipmentTypes.substation) {
                return true;
            }
            return false;
        },
        //主变跳转实时数据
        toMainSubstationCurrentData() {
            let type = this.popData.pointData.equipmentMainCategory;
            if (type === equipmentTypes.mainSubstation) {
                return true;
            }
            return false;
        },
        //环网柜跳转实时数据
        toHwgQcy1() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-QCYIH-#1K') > -1) {
                return true;
            }
            return false;
        },
        toHwgQcy2() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-QCYⅡH-#1K') > -1) {
                return true;
            }
            return false;
        },
        toHwgYx1() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXX-#1K') > -1) {
                return true;
            }
            return false;
        },
        toHwgYx2() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXX-#2K') > -1) {
                return true;
            }
            return false;
        },
        toHwgYx3() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXX-#3K') > -1) {
                return true;
            }
            return false;
        },
        toHwgYx4() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXX-#4K') > -1) {
                return true;
            }
            return false;
        },
        toHwgYk1() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YKX-#1K') > -1) {
                return true;
            }
            return false;
        },
        toHwgYk2() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YKX-#2K') > -1) {
                return true;
            }
            return false;
        },
        toHwgYk3() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YKX-#3k') > -1) {
                return true;
            }
            return false;
        },
        toHwgYj1() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YJX-#1K') > -1) {
                return true;
            }
            return false;
        },
        toHwgYj2() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YJX-#2K') > -1) {
                return true;
            }
            return false;
        },
        toHwgYx2Add1() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('暂留') > -1) {
                return true;
            }*/
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.ringMainUnit && equipmentId === 28) {
                return true;
            }
            return false;
        },
        toHwgYx1Hui1() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-1K') > -1) {
                return true;
            }
            return false;
        },
        toHwgYx1Hui2() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }
            return false;
        },
        toHwgYx2Hui1() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXIIHX-1K') > -1) {
                return true;
            }
            return false;
        },
        toHwgHs1xiang() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.substation && equipmentId === 39) {
                return true;
            }
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }*/
            return false;
        },
        toHwgHs2xiang() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.substation && equipmentId === 40) {
                return true;
            }
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }*/
            return false;
        },
        toHwgHs3xiang() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.substation && equipmentId === 41) {
                return true;
            }
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }*/
            return false;
        },
        toHwgHs4xiang() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.substation && equipmentId === 42) {
                return true;
            }
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }*/
            return false;
        },
        toHwgHs5xiang() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.substation && equipmentId === 49) {
                return true;
            }
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }*/
            return false;
        },
        toHwgHs6xiang() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.substation && equipmentId === 43) {
                return true;
            }
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }*/
            return false;
        },
        toHwgXdtt1h1xiang() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.substation && equipmentId === 44) {
                return true;
            }
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }*/
            return false;
        },
        toHwgXdtt1h2xiang() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.substation && equipmentId === 45) {
                return true;
            }
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }*/
            return false;
        },
        toHwgXdtt2h1xiang() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.substation && equipmentId === 47) {
                return true;
            }
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }*/
            return false;
        },
        toHwgXdtt2h2xiang() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.substation && equipmentId === 48) {
                return true;
            }
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }*/
            return false;
        },
        toHwgXdtt2h3xiang() {
            let type = this.popData.pointData.equipmentMainCategory;
            let name = this.popData.pointData.equipmentName;
            let equipmentId = this.popData.pointData.equipmentId;
            if (type === equipmentTypes.substation && equipmentId === 46) {
                return true;
            }
            /*if (type === equipmentTypes.ringMainUnit && name.indexOf('QCYB-YXⅠHX-#2K') > -1) {
                return true;
            }*/
            return false;
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateDragger);
    },
    methods: {
        searchAddressInfo(key) {
            let local = new BMap.LocalSearch(globalMap, {
                renderOptions: {map: globalMap},
            });
            local.search(key);
        },
        showPointDetail(data) {
            const componentTypeTrans = {
                RING_MAIN_UNIT: 'RingMainUnit',
                SUBSTATION: 'Substation',
            };
            this.pointDialog.visible = true;
            this.pointDialog.title = data.equipmentName;
            this.pointDialog.componentType = componentTypeTrans[data.equipmentMainCategory];
        },
        handleResize: debounce(function (obj) {
            if (this._chart) {
                this._chart.resize();
            }
        }, 100),
        updateAll(resetView) {
            if (this._chart) {
                if (resetView) {
                    this.resetCenter();
                }
                this.updatePoint();
                this.updateLine();
                this.updateDragger();
            }
        },
        resetCenter() {
            // 根据当前线路点计算缩放
            //let points = this.points.filter((it) => it.inCurrentGrid);
            //以主变居中显示
            let points = this.points.filter((it) => it.equipmentMainCategory === equipmentTypes.mainSubstation);
            if (!points.length) {
                points = this.points;
            }
            let data = getCenterPointAndZoom(points, this._chart);
            if (data) {
                let {coords, zoom} = data;
                this._chart.setOption({
                    bmap: {
                        center: coords,
                        zoom,
                    },
                });
            }
        },
        showPointTooltip(index) {
            this._chart.dispatchAction({
                type: 'showTip',
                seriesIndex: 1,
                dataIndex: index,
            });
        },
        initializeChart() {
            // this.opt.bmap = {
            //     center: [102.714, 25.049],
            //     zoom: 12,
            // };
            // // 基于准备好的dom，初始化echarts实例
            let myChart = echarts.init(document.getElementById('chartwrap'));
            this._chart = myChart;
            myChart.setOption(this.opt);
            this.updateAll(true);
            window.addEventListener('resize', this.updateDragger);
            myChart.on('bmapRoam', this.handleBmapRoam);
            myChart.on('click', this.handleChartClick);

            globalMap = myChart.getModel().getComponent('bmap').__bmap;
            if (this.editable) {
                myChart.on('contextmenu', this.handleChartRightClick);
                globalMap.addEventListener('rightclick', this.handleMapRightClick);
            }
            globalMap.addEventListener('click', (e) => {
                isMapLayerClick('map', e, this.handleMapLayerClick);
                if (this.editable) {
                    isMapLayerClick('map', e, this.drawLines);
                }
            });
            globalMap.addEventListener('zoomend', (e) => {
                this.updateMarkPoint(globalMap.getZoom());
            });
        },
        handleBmapRoam() {
            this.$emit('bmapRoam');
            this.updateDragger();
        },
        handleChartClick(e) {
            if (!this.isLineEdit) {
                let {offsetX, offsetY} = e.event;
                let popPosition = {
                    left: offsetX + 'px',
                    top: offsetY + 'px',
                };
                let data, popTitle, popContent, type, index;
                if (e.componentType === 'graphic') {
                    data = e.info.poinData;
                    type = 'point';
                    index = e.info.dataIndex;
                } else if (e.componentType === 'series' && e.componentSubType === 'lines') {
                    data = e.data;
                    type = 'line';
                    index = e.dataIndex;
                } else if (e.componentType === 'series' && e.componentSubType === 'scatter') {
                    //symbolSize根据数据改变大小后，componentType === 'series',e.componentSubType === 'scatter'
                    data = e.data.info;
                    type = 'point';
                    index = e.dataIndex;
                }
                this.popData.popPosition = popPosition;
                this.$emit('itemClick', type, index, data);
                isMapLayerClick('item', e, this.handleMapLayerClick);
            }
        },
        lineMenuHandle(code) {
            switch (code) {
                case 'edit': {
                    this.isLineEdit = true;
                    this.clearDrawLines();
                    let coords = this.currentLine.coords[0];
                    this.addDrawLine(coords);
                    this.$message({
                        message: '请点击地图添加拐点',
                        type: 'info',
                        duration: 3000,
                    });
                    //删除原来的线
                    let lineSeries = this.getSeries('line');
                    this.currentEditLine = lineSeries.data.splice(this.currentLine.dataIndex, 1)[0];
                    this._chart.setOption({
                        series: this.opt.series,
                    });
                    break;
                }
                case 'cancel': {
                    this.isLineEdit = false;
                    let lineSeries = this.getSeries('line');
                    lineSeries.data.push(this.currentEditLine);
                    this._chart.setOption({
                        series: this.opt.series,
                    });
                    this.clearDrawLines();
                    break;
                }
                case 'back':
                    if (this.tempLinePoint.length > 1) {
                        this.tempLinePoint.pop();
                        this.tempLinePointCoord.pop();
                        this.linePoint.setPath(this.tempLinePoint);
                        globalMap.addOverlay(this.linePoint); //绘制曲线
                    }
                    break;
                case 'reset':
                    this.$client.resetPowerGridsLine(this.currentLine.info.id).then((res) => {
                        this.clearDrawLines();
                        //恢复原来的线
                        let coords = this.currentLine.coords;
                        let lineSeries = this.getSeries('line');
                        lineSeries.data.splice(this.currentLine.dataIndex, {
                            coords: [coords[0], coords[coords.length - 1]],
                            info: this.currentLine.info,
                        });
                        this._chart.setOption({
                            series: this.opt.series,
                        });
                    });
                    break;
                case 'save': {
                    this.isLineEdit = false;
                    let coords = this.currentLine.coords[this.currentLine.coords.length - 1];
                    this.addDrawLine(coords);
                    this.saveDrawLine();
                    break;
                }
            }
        },
        saveDrawLine() {
            this.$client.savePowerGridsLines(this.currentLine.info.id, this.tempLinePointCoord).then((res) => {
                //更新拆线
                this.$message({
                    message: '保存成功！',
                    type: 'success',
                });
                let lineSeries = this.getSeries('line');
                let coords = this.tempLinePointCoord.map((item) => {
                    return [item.longitude, item.latitude];
                });
                lineSeries.data.push({
                    coords,
                    info: this.currentLine.info,
                });
                this._chart.setOption({
                    series: this.opt.series,
                });
                this.clearDrawLines();
            });
        },
        addDrawLine(coords, isPoint) {
            let lng = coords[0];
            let lat = coords[1];
            this.tempLinePoint.push(new BMap.Point(lng, lat)); //存储曲线上每个点的经纬度
            if (!isPoint) {
                this.tempLinePointCoord.push({
                    longitude: lng,
                    latitude: lat,
                    gridLineId: this.currentLine.info.id,
                });
            }
            //如果曲线存在，则获取折线上的点
            if (this.linePoint) {
                this.linePoint.setPath(this.tempLinePoint);
            } else {
                this.linePoint = new BMap.Polyline(this.tempLinePoint, {
                    strokeColor: 'blue',
                });
            } //如果折线不存在，就增加此点
            if (this.tempLinePoint.length < 2) {
                return;
            } //当折线上的点只有一个时，不绘制
            globalMap.addOverlay(this.linePoint); //绘制曲线
        },
        clearDrawLines() {
            globalMap.removeOverlay(this.linePoint);
            this.linePoint = null;
            this.tempLinePoint.length = 0;
            this.tempLinePointCoord.length = 0;
        },
        drawLines(e) {
            //判断是否绘制曲线完毕
            if (this.isLineEdit) {
                this.addDrawLine([e.point.lng, e.point.lat]);
            }
        },
        handleMapRightClick(e) {
            if (this.isLineEdit) {
                this.$refs.lineMenu.show({
                    left: e.clientX,
                    top: e.clientY,
                });
            }
        },
        handleChartRightClick(e) {
            //处理线路右健
            if (e.componentType === 'series' && e.componentSubType === 'lines' && !this.isLineEdit) {
                let {
                    offsetX,
                    offsetY,
                    event: {clientX, clientY},
                } = e.event;
                let popPosition = {
                    left: offsetX + 'px',
                    top: offsetY + 'px',
                };
                let data, popTitle, popContent, type;
                data = e.data;
                type = 'line';
                this.currentLine = {...data};
                this.currentLine.dataIndex = e.dataIndex;
                this.$refs.lineMenu.show({
                    left: clientX,
                    top: clientY,
                });
            }
        },
        handleMapLayerClick(e) {
            let pt = e.point;
            if (this.emitAddress) {
                let geoc = new BMap.Geocoder();
                let that = this;
                geoc.getLocation(pt, function (rs) {
                    //addressComponents对象可以获取到详细的地址信息
                    let addComp = rs.addressComponents;
                    let address =
                        addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber;
                    that.$emit('mapLayerClick', {longitude: pt.lng, latitude: pt.lat, address});
                });
            } else {
                this.$emit('mapLayerClick', {longitude: pt.lng, latitude: pt.lat});
            }
        },
        async showPopper(popTitle, popContent, pointData) {
            this.popData.popTitle = popTitle;
            this.popData.popContent = popContent;
            this.popData.pointData = pointData;
            await this.$nextTick();
            this.$refs.popover.showPopper = true;
            await this.$nextTick();
            let dom = document.querySelector('.map-popover');
            dom.style.zIndex = 0;
        },
        loadScript() {
            let script = document.createElement('script');
            script.src =
                'https://api.map.baidu.com/api?v=3.0&ak=xlNXFwlYUbBvq3ZSRUlk5tkDiZcKb1Lr&callback=initializeChart';
            document.body.appendChild(script);

            window.initializeChart = this.initializeChart;
        },
        getSeries(type) {
            if (type === 'line') {
                return this.opt.series[0];
            } else if (type === 'point') {
                return this.opt.series[1];
            }
        },
        hidePopover() {
            if (this.$refs.popover) {
                this.$refs.popover.showPopper = false;
            }
        },
        updateLine(noUpdate) {
            let lineSeries = this.getSeries('line');
            lineSeries.data = toLineData(this.lines, this.points, this.gridLines);
            // if (!lineSeries.data || !lineSeries.data.length) {
            //     return;
            // }
            if (!noUpdate) {
                this._chart.setOption({
                    series: this.opt.series,
                });
            }
        },
        updateMarkPoint(zoom) {
            console.log('缩放级数：', zoom);
            let serie = this.opt.series[1];
            /*if (zoom >= globalZoom) {
                if (!serie.markPoint) {
                    let dataArr = this.points.map(item => {
                        return {name: item.title ? item.title : item.equipmentName, coord: item.coords};
                    });
                    let markPoint = {
                        symbol: 'rect',
                        symbolOffset: [0, -30],
                        symbolSize(value, params) {// 容器大小
                            let name = params.name || '';
                            let len = gblen(name) * 7;
                            return [len, 30];
                        },
                        data: dataArr,
                        effect: {
                            show: true,
                            shadowBlur: 0
                        },
                        itemStyle: {
                            color: 'rgba(22, 29, 48, 0.9)'
                        },
                        label: {
                            offset: [0, 0],
                            color: '#ffffff',
                            formatter(param) {
                                return param.data.name;
                            }
                        }
                    };
                    serie.markPoint = markPoint;
                } else {
                    serie.markPoint.symbolOffset = [0, -30];
                }
            } else if (serie.markPoint) {
                serie.markPoint.symbolOffset = [-99999999, -99999999];
            }*/
            if (zoom >= globalZoom) {
                serie.symbolSize = symbolSize1;
            } else {
                serie.symbolSize = symbolSize;
            }
            //if (serie.markPoint) {
            let myChart = this._chart;
            myChart.setOption(
                {
                    series: this.opt.series,
                },
                {
                    notMerge: false,
                    lazyUpdate: true,
                    silent: true,
                }
            );
            //}
        },
        updatePoint() {
            console.log('updatePoint');
            let pointSeries = this.getSeries('point');
            pointSeries.data = toPointsData(this.lines, this.points);
            // if (!pointSeries.data || !pointSeries.data.length) {
            //     return;
            // }

            let myChart = this._chart;
            myChart.setOption(
                {
                    // graphic: graphic,
                    series: this.opt.series,
                },
                {
                    notMerge: false,
                    lazyUpdate: true,
                    silent: true,
                }
            );
        },
        updateDragger() {
            this.hidePopover();
            let myChart = this._chart;
            let bmapModel = myChart.getModel().getComponent('bmap');
            let bmapCoord = bmapModel.coordinateSystem;
            let oldGrapic = this.opt.graphic || {};
            let vueComponent = this;
            let series = this.getSeries('point');
            let mapItemIndex = this.mapItemIndex;
            // if (!this.points || !this.points.length) {
            //     return;
            // }
            let graphic = echarts.util.map(this.points, function (item, dataIndex) {
                // 只有当选中当前点时才能拖动点
                let draggable = false;
                if (mapItemIndex === dataIndex) {
                    draggable = true;
                }
                if (oldGrapic[dataIndex]) {
                    return {
                        id: dataIndex,
                        position: bmapCoord.dataToPoint(item.coords),
                    };
                }
                return {
                    // id: dataIndex,
                    type: 'circle',
                    position: bmapCoord.dataToPoint(item.coords),
                    shape: {
                        cx: 0,
                        cy: 0,
                        r: series.symbolSize / 2,
                    },
                    info: {
                        dataIndex,
                        poinData: item,
                    },
                    // style: {
                    //     fill: 'red',
                    // },
                    invisible: true,
                    draggable,
                    onmouseover: echarts.util.curry(handlePointOver, dataIndex, vueComponent),
                    onmouseout: echarts.util.curry(handlePointOut, dataIndex, vueComponent),
                    ondrag: throttle(echarts.util.curry(handlePointDragging, dataIndex, vueComponent), 60),
                    onmousemove: throttle(echarts.util.curry(handlePointMove, dataIndex, vueComponent), 60),
                    // onmouseout: echarts.util.curry(hideTooltip, dataIndex),
                    z: 100,
                };
            });

            myChart.setOption({
                graphic,
            });
        },

        drop(e) {
            let text = e.dataTransfer.getData('Data');
            text = JSON.parse(text);
            const {clientX, clientY} = e;
            const {x, y} = this.$refs.mapWrap.getBoundingClientRect();
            const offsetX = clientX - x;
            const offsetY = clientY - y;

            let position = [offsetX, offsetY];
            let myChart = this._chart;

            let bmapModel = myChart.getModel().getComponent('bmap');
            let bmapCoord = bmapModel.coordinateSystem;
            let newCoords = bmapCoord.pointToData(position);

            this.points.push({
                id: uniqueId('unsaved_'),
                coords: newCoords,
                ...text,
            });
            this.updatePoint();
            this.updateDragger();
        },
        /* eslint-disable space-before-function-paren */
        dragover: throttle(function (e) {
            this.offsetX = e.offsetX;
            this.offsetY = e.offsetY;
        }, 150),
        setEquipmentLocation(data) {
            let point = new BMap.Point(data.longitude, data.latitude); // 创建点坐标
            globalMap.centerAndZoom(point, globalZoom); // 初始化地图，设置中心点坐标和地图级别
            /*let dataIndex=this.points.findIndex(item=> item.longitude==data.longitude&&item.latitude==data.latitude);
            console.log('dataIndex',dataIndex,data)
            this._chart.dispatchAction({
                type: 'select',
                // 可选，系列 index，可以是一个数组指定多个系列
                seriesIndex: 1,
                dataIndex: dataIndex,
            })*/
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';
.chart-map {
    flex-grow: 1;
    &__more-link {
        cursor: pointer;
        margin-top: 10px;
        &:hover {
            color: @bg-btn-light;
        }
    }
    &__drop-zone {
        position: relative;
        height: 100%;
    }
    &__pop-trigger {
        position: absolute;
        width: 0;
        height: 0;
        background-color: lawngreen;
    }
    &__tooltip {
        position: absolute;
        font-size: 12px;
        padding: 5px;
        color: #fff;
        background-color: #333a;
        border-radius: 5px;
    }
    &__search {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        width: 100%;
    }
    &__panel {
        background-color: rgba(22, 29, 48, 0.9);
        z-index: 9;
        position: absolute;
        top: 10px;
        left: 40%;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px 20px;
    }
}
</style>
