import {equipmentTypeTrans, equipmentTypes} from './utils';
export default {
    methods: {
        handleShowPointsChange(data) {
            this.showPoints = data;
            // 防止update时点和线没有传到子组件
            this.$nextTick(() => {
                this.$refs.mapbase.updateAll(true);
                if (this.$refs.editForm) {
                    this.$refs.editForm.hide();
                }
            });
        },
        handleReFetchMapData() {
            this.getLayerData(this.$route.params.id);
        },
        handleMapLayerClick(attr) {
            if (this.$refs.editForm && this.$refs.editForm.baseValues && this.$refs.editForm.baseValues.isMove) {
                //移动
                let latitude = attr.latitude;
                let longitude = attr.longitude;
                this.$refs.editForm.baseValues.latitude = latitude;
                this.$refs.editForm.baseValues.longitude = longitude;
                let coords = [longitude, latitude];
                this.handleCoordsChange(coords);
            } else {
                this.mapItemIndex = undefined;
                if (this.$refs.editForm) {
                    this.$refs.editForm.hide();
                }
            }
        },
        async showPopper(data) {
            let result;
            let deviceName = '',
                inputs = '',
                location = '';
            let et = it => (it ? it : '');
            if (/unsaved/.test(data.id)) {
                return;
            }
            if (data.equipmentMainCategory === equipmentTypes.ringMainUnit) {
                result = await this.$client.getPowerGridsEquipmentsRingMainUnits(data.id).then(res => {
                    return res.data;
                });
                location = `<br/>经度：${result.longitude}<br/>纬度：${result.latitude}<br/>`;
                deviceName = result.equipmentName;
                inputs = result.inputBoxList
                    .map(it => {
                        return `接入点：${et(it.joinBoxName)}<br/>接入点线路：${et(it.wireName)}`;
                    })
                    .join('<br/>');
                //实时电流、电压、开关状态
                let realtimeData = await this.$client.queryRingMainUnitRealData(data.id).then(res => {
                    return res.data;
                });
                if (realtimeData && realtimeData.length) {
                    inputs += `<br/>`;
                    realtimeData.map(item => {
                        inputs += `<br/><div>
                                        ${item.cabinet}&nbsp;&nbsp;&nbsp;&nbsp;
                                        电流：${item.current}A&nbsp;&nbsp;&nbsp;&nbsp;
                                        电压：${item.voltage}Kv<br/>
                                        ${item.switchDStr}&nbsp;&nbsp;&nbsp;&nbsp;
                                        ${item.switchGStr}<br/>
                                        ${item.switchJStr}
                                        </div>`;
                    });
                }
            } else if (data.equipmentMainCategory === equipmentTypes.substation) {
                result = await this.$client.getPowerGridsEquipmentsSubstations(data.id).then(res => {
                    return res.data;
                });
                location = `<br/>经度：${result.longitude}<br/>纬度：${result.latitude}<br/>`;
                deviceName = result.equipmentName;
                inputs = result.inputBoxList
                    .map(it => {
                        return `接入点：${et(it.joinBoxName)}<br/>接入点线路：${et(it.wireName)}`;
                    })
                    .join('<br/>');
            } else if (data.equipmentMainCategory === equipmentTypes.towerPole) {
                result = await this.$client.getPowerGridsEquipmentsTowerPoles(data.id).then(res => {
                    return res.data;
                });
                location = `<br/>经度：${result.longitude}<br/>纬度：${result.latitude}<br/>`;
                deviceName = result.equipmentName;
                inputs = result.loops
                    .map(it => {
                        return `接入点：${et(it.inputJoinBoxName)}<br/>接入点线路：${et(it.inputWireName)}`;
                    })
                    .join('<br/>');
            } else if (data.equipmentMainCategory === equipmentTypes.mainSubstation) {
                result = await this.$client.getPowerGridsEquipmentsMainSubstation(data.id).then(res => {
                    return res.data;
                });
                location = `<br/>经度：${result.longitude}<br/>纬度：${result.latitude}<br/>`;
                deviceName = result.equipmentName;
                inputs = '';
            }
            this.$refs.mapbase.showPopper(deviceName, `${inputs}`, result);
        },
        handleBmapRoam() {},
        getPowerGridsEquipments(id) {
            return this.$client.getPowerGridsEquipments(id).then(res => {
                let points = res.data.map(it => {
                    it.categoryName = equipmentTypeTrans[it.equipmentMainCategory];
                    it.coords = [it.longitude, it.latitude];
                    return it;
                });
                // this.points = points;
                // this.$refs.mapbase.updateAll();
                return points;
            });
        },
        queryCapacityInfo() {
            return this.$client.getPowerGridStatusInfo().then((ret) => {
                return ret.data;
            });
        },
        getPowerGridsWires(id) {
            return this.$client.getPowerGridsWires(id).then(res => {
                let lines = [];
                res.data.map(it => {
                    it.name = it.wireName;
                    it.pointIds = [it.inputGridEquipmentId, it.outputGridEquipmentId];
                    // 过滤没有入线点或者出线点的线
                    if (it.inputGridEquipmentId && it.outputGridEquipmentId) {
                        lines.push(it);
                    }
                });
                return lines;
            });
        },
        getPowerGridsLines() {
            return this.$client.getPowerGridsLines().then(res => {
                return res.data;
            });
        },
        async getLayerData(id, callback) {
            let [points, lines, capacityInfo, gridLines] = await Promise.all([this.getPowerGridsEquipments(id), this.getPowerGridsWires(id), this.queryCapacityInfo(), this.getPowerGridsLines()]);
            //设置主变负荷信息
            if (points && capacityInfo) {
                points.map(it => {
                    if (it.equipmentMainCategory === equipmentTypes.mainSubstation) {
                        it.totalCapacity = capacityInfo.totalCapacity ? capacityInfo.totalCapacity : 0;
                        it.currentCapacity = capacityInfo.currentCapacity ? capacityInfo.currentCapacity : 0;
                        it.lastCapacity = it.totalCapacity - it.currentCapacity;
                    }
                });
            }
            this.showPoints = points;
            this.allPoints = this._.cloneDeep(points);
            this.lines = lines;
            this.gridLines = gridLines;
            // 防止update时点和线没有传到子组件
            this.$nextTick(() => {
                this.$refs.mapbase.updateAll(true);
                callback && callback();
            });
        }
    }
};
