import {assign} from 'lodash';
import {dataScheduleFormSubmit} from '@/client';
export const equipmentTypes = {
    mainSubstation: 'MAIN_SUBSTATION',
    substation: 'SUBSTATION',
    towerPole: 'TOWER_POLE',
    towerPoleSwitch: 'TOWER_POLE_SWITCH',
    ringMainUnit: 'RING_MAIN_UNIT',
    wire: 'WIRE'
};

// export const statusTypes = {
//     normal: 'NORMAL',
//     risky: 'RISKY',
//     damaged: 'DAMAGED',
//     inMaintenance: 'IN_MAINTENANCE',
//     idle: 'IDLE',
//     failure: 'FAILURE',
//     abandonment: 'ABANDONMENT'
// };
// export const statusTypesTrans = {
//     NORMAL: '正常',
//     RISKY: '安全隐患',
//     DAMAGED: '损坏',
//     IN_MAINTENANCE: '维修中',
//     IDLE: '闲置',
//     FAILURE: '故障',
//     ABANDONMENT: '废弃'
// };
export const equipmentTypeTrans = {
    MAIN_SUBSTATION: '主变',
    SUBSTATION: '变电站',
    TOWER_POLE: '杆塔',
    TOWER_POLE_SWITCH: '杆上开关',
    RING_MAIN_UNIT: '环网柜',
    WIRE: '导线'
};

export function toPointsData(lines, points) {
    let pointResult = points.map((it, index) => {
        return {
            value: [...it.coords],
            info: assign({itemIndex: index}, it)
        };
    });
    let switchLocation = [];
    lines.map(it => {
        if (
            (it.outputJoinType === 'CROSS' && it.outputGridEquipmentId) ||
            (it.inputJoinType === 'OVER' && it.inputGridEquipmentId)
        ) {
            if (it.inputGridEquipmentId && it.outputGridEquipmentId) {
                switchLocation.push([it.inputGridEquipmentId, it.outputGridEquipmentId]);
            }
        }
    });
    let switches = [];
    switchLocation.map(it => {
        let discard = false;
        let coords = it.map(id => {
            let point = points.filter(point => point.id === id)[0];
            if (point) {
                return [...point.coords];
            }
            discard = true;
            return [];
        });
        if (!discard) {
            let [[a, b], [c, d]] = coords;
            coords = [(Number(a) + Number(c)) / 2, (Number(b) + Number(d)) / 2];
            switches.push({
                value: coords,
                info: {
                    categoryName: '开关',
                    equipmentMainCategory: 'SWITCH'
                }
            });
        }
    });

    return [...pointResult, ...switches];
}
export function toLineData(lines, points, gridLines) {
    let result = [];
    lines.map((it, index) => {
        let {pointIds} = it;
        let discard = false;

        let coords = pointIds.map(id => {
            let point = points.filter(point => point.id === id)[0];
            if (point) {
                return [...point.coords];
            }
            discard = true;
            return [];
        });
        //插入拆线
        let drawLineCoords = gridLines.filter(drawLine => drawLine.gridLineId === it.id).map(drawLine => {
            return [drawLine.longitude, drawLine.latitude];
        });
        if (drawLineCoords.length > 0) {
            //coords = [coords[0], ...drawLineCoords, coords[1]];
            coords = drawLineCoords;
        }
        if (!discard) {
            result.push({
                coords,
                info: assign({itemIndex: index}, it)
            });
        }
    });
    return result;
}

/**
 *
 * 地图图标和线点击时，底图也会触发点击事件，本方法用来过滤点线点击事件，只触发底图点击
 * @param type 触发的类型 item, map
 * @param callback 回调事件
 *
 */
let maplayerTimer;
let mapClickType;
export function isMapLayerClick(type, e, callback) {
    if (!mapClickType) {
        mapClickType = type;
    }
    if (mapClickType === type) {
        maplayerTimer = setTimeout(() => {
            callback && callback(e);
        }, 80);
    } else {
        clearTimeout(maplayerTimer);
    }
    mapClickType = type;
}

// 计算点坐标的中心点和缩放倍数
export function getCenterPointAndZoom(points, myChart) {
    if (!points.length) {
        return;
    }
    if (points.length > 0) {
        let maxLng = points[0].longitude;
        let minLng = points[0].longitude;
        let maxLat = points[0].latitude;
        let minLat = points[0].latitude;
        let res;
        for (let i = points.length - 1; i >= 0; i--) {
            res = points[i];
            if (res.longitude > maxLng) {
                maxLng = res.longitude;
            }
            if (res.longitude < minLng) {
                minLng = res.longitude;
            }
            if (res.latitude > maxLat) {
                maxLat = res.latitude;
            }
            if (res.latitude < minLat) {
                minLat = res.latitude;
            }
        }
        let cenLng = (parseFloat(maxLng) + parseFloat(minLng)) / 2;
        let cenLat = (parseFloat(maxLat) + parseFloat(minLat)) / 2;
        let zoom = getZoom(maxLng, minLng, maxLat, minLat, myChart);

        return {coords: [cenLng, cenLat], zoom};
    }
    return;
}

//根据经纬极值计算绽放级别。
function getZoom(maxLng, minLng, maxLat, minLat, myChart) {
    let zoom = [
        '50',
        '100',
        '200',
        '500',
        '1000',
        '2000',
        '5000',
        '10000',
        '20000',
        '25000',
        '50000',
        '100000',
        '200000',
        '500000',
        '1000000',
        '2000000'
    ]; //级别18到3。
    let pointA = new BMap.Point(maxLng, maxLat); // 创建点坐标A
    let pointB = new BMap.Point(minLng, minLat); // 创建点坐标B
    let distance = myChart
        .getModel()
        .getComponent('bmap')
        .__bmap.getDistance(pointA, pointB)
        .toFixed(1); //获取两点距离,保留小数点后两位
    console.log('distance', distance);
    if (Number(distance) === 0) {
        return 18;
    }
    for (let i = 0, zoomLen = zoom.length; i < zoomLen; i++) {
        if (zoom[i] - distance > 0) {
            return 18 - i + 3; //之所以会多3，是因为地图范围常常是比例尺距离的10倍以上。所以级别会增加3。
        }
    }
}
